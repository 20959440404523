var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "keepMaterialJiesuan" },
    [
      _vm.ishow
        ? [
            _c("van-empty", {
              staticClass: "custom-image",
              attrs: {
                image: require("@/assets/images/pic_no information@2x.png"),
                description: "暂无数据!"
              }
            })
          ]
        : _vm._e(),
      _c(
        "van-pull-refresh",
        {
          attrs: { "success-text": "刷新成功" },
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing"
          }
        },
        [
          !_vm.ishow
            ? _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": "没有更多了"
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                _vm._l(_vm.list, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "cell",
                      on: {
                        click: function($event) {
                          return _vm.ToStockBalance(item)
                        }
                      }
                    },
                    [
                      item.mortgageFreeWeight === 0 ||
                      item.orderType === "PRE_MATERIAL"
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "title",
                                  style: {
                                    backgroundColor:
                                      item.orderType == "MATERIAL"
                                        ? "#ff2d2e"
                                        : "#feb706"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      item.orderType == "MATERIAL" ? "尾" : "预"
                                    )
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "flex" }, [
                                _c("div", { staticClass: "left" }, [
                                  _c("p", { staticClass: "DiscountWeight" }, [
                                    _vm._v(
                                      "提料总重 : " +
                                        _vm._s(item.weight.toFixed(2)) +
                                        "g"
                                    )
                                  ]),
                                  _c("div", { staticClass: "time" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate_")(item.created)
                                      )
                                    )
                                  ])
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "right",
                                    style: {
                                      color:
                                        item.payStatus == "WAIT_CONFIRM"
                                          ? "red"
                                          : item.payStatus == "WAIT"
                                          ? "#ff2d2e"
                                          : "#ccc"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "config" }, [
                                      _vm._v(
                                        _vm._s(
                                          item.payStatus == "WAIT_CONFIRM"
                                            ? "待确认"
                                            : item.payStatus == "PAYED" &&
                                              item.orderType == "PRE_MATERIAL"
                                            ? "待交料"
                                            : item.payStatus == "PAYED" &&
                                              item.orderType == "MATERIAL"
                                            ? "已收款"
                                            : item.payStatus == "WAIT" &&
                                              item.orderType == "PRE_MATERIAL"
                                            ? "待付款"
                                            : item.payStatus == "WAIT" &&
                                              item.orderType == "MATERIAL"
                                            ? "待收款"
                                            : "已提料"
                                        )
                                      )
                                    ]),
                                    _c("div", { staticClass: "money" }, [
                                      _vm._v(
                                        _vm._s(
                                          item.fixedRemainMoney
                                            ? item.fixedRemainMoney > 0
                                              ? "+" +
                                                item.fixedRemainMoney.toFixed(2)
                                              : item.fixedRemainMoney.toFixed(2)
                                            : item.money > 0
                                            ? "+" + item.money.toFixed(2)
                                            : item.money
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ]),
                              _c("van-icon", { attrs: { name: "arrow" } })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }